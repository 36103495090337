<template>
  <div class="content">
    <div class="modal-interests" v-show="showModal" @click="showModal = false">
      <Link
        :to="{
          name: 'category',
          params: {
            category: $route.params.category,
          },
        }"
        class="item"
      >
        Semua
      </Link>
      <Link
        v-for="(item, index) in interests.items"
        :key="`btn-interest-mobile-${index}`"
        :to="{
          name: 'category-slug',
          params: {
            category: $route.params.category,
            slug: item.slug,
          },
        }"
        class="item"
        :class="{
          active:
            item.slug == $route.params.slug ||
            item.slug.includes($route.params.slug),
        }"
      >
        {{ item.title }}
      </Link>
    </div>
    <section id="interests">
      <CategoryTopAds />
      <div class="container">
        <TitleSection
          :title="
            $route.params.category == 'tech-edu'
              ? 'TECH & EDU'
              : $route.params.category.replace(/-/g, ' ')
          "
          size="xl"
          class="title-section"
        />
        <div
          class="py-2 is-desktop"
          v-swiper:mySwiperInsterests="swiperOptionsInterests"
        >
          <div class="swiper-wrapper">
            <ShimmerCategory v-if="interests.loading" />
            <Link
              v-show="!interests.loading"
              class="swiper-slide category"
              :to="{
                name: 'category',
                params: {
                  category: $route.params.category,
                },
              }"
              >Semua
            </Link>
            <Link
              v-show="!interests.loading"
              v-for="(item, index) in interests.items"
              :key="`btn-interest-${index}`"
              class="swiper-slide category"
              :class="
                $route.params.slug == item.slug ||
                item.slug.includes($route.params.slug)
                  ? 'Link-exact-active'
                  : ''
              "
              :to="{
                name: 'category-slug',
                params: {
                  category: $route.params.category,
                  slug: item.slug,
                },
              }"
              >{{ item.title }}
            </Link>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="dropdown is-mobile" @click="show()">
          <div class="select">
            <p class="label">
              {{
                $route.params.slug == "semua" && !interestDetail
                  ? "Semua"
                  : interestDetail.title
              }}
            </p>
            <Icons name="arrow-bottom" />
          </div>
        </div>
      </div>
    </section>
    <section id="hero">
      <div
        v-if="pinned_article && pinned_article.channel"
        class="container"
        :style="{
          backgroundImage: `url(${require('@/static/storage/images/bg-hero-banner.png')})`,
        }"
      >
        <Link
          :to="
            pinned_article.isText
              ? {
                  name: `read-channel-slug`,
                  params: {
                    channel: pinned_article.channel.slug,
                    slug: pinned_article.slug,
                  },
                  query: {
                    ref: 'interest-detail',
                  },
                }
              : {
                  name: 'video-channel-slug',
                  params: {
                    channel: pinned_article.channel.slug,
                    slug: pinned_article.slug,
                  },
                  query: {
                    ref: 'interest-detail',
                  },
                }
          "
          class="img-thumb"
        >
          <ImageResponsive
            :imageUrl="
              pinned_article.thumbnail && pinned_article.thumbnail.large
                ? pinned_article.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy-banner.png'
            "
            :fromUrl="true"
            width="461"
            height="258"
            widthMobile="461"
            heightMobile="258"
          />
        </Link>
        <div
          class="copy-wrapper"
          v-if="pinned_article && pinned_article.channel"
        >
          <Link
            :to="{
              name: 'program-childBrand-slugProgram',
              params: {
                childBrand: pinned_article.channel.program.slug,
                slugProgram: pinned_article.channel.slug,
              },
              query: {
                type: 'highlights',
              },
            }"
            class="category"
          >
            <p class="channel">{{ pinned_article.channel.title }}</p>
          </Link>
          <Link
            :to="
              pinned_article.isText
                ? {
                    name: `read-channel-slug`,
                    params: {
                      channel: pinned_article.channel.slug,
                      slug: pinned_article.slug,
                    },
                    query: {
                      ref: 'interest-detail',
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: pinned_article.channel.slug,
                      slug: pinned_article.slug,
                    },
                    query: {
                      ref: 'interest-detail',
                    },
                  }
            "
          >
            <h1 class="title">
              {{ pinned_article.title }}
            </h1>
          </Link>
        </div>
      </div>
    </section>
    <section id="articles">
      <div class="container">
        <div class="primary">
          <TitleSection title="TERKINI" class="title-section" />
          <div class="list-article">
            <template v-if="artikel_terkini.loading">
              <ShimmerCardHomeLandscape
                v-for="(item, index) in 5"
                :key="index"
              />
            </template>
            <CardFive
              v-show="!artikel_terkini.loading"
              v-for="(item, index) in artikel_terkini.items.slice(
                1,
                artikel_terkini.items.length
              )"
              :key="`article-${index}`"
              :image="
                item.thumbnail && item.thumbnail.large
                  ? item.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :imageFromUrl="true"
              :duration="item.timeVideo ? item.timeVideo : '06:00'"
              :channel="item.channel.title"
              :channelSlug="item.channel.slug"
              :programSlug="
                item.channel.program ? item.channel.program.slug : ''
              "
              :category="item.category ? item.category.title : ''"
              :categorySlug="item.category ? item.category.slug : ''"
              :title="item.title"
              :isText="item.isText"
              :isAdvertorial="item.isAdvertorial"
              :withProgram="false"
              :to="
                item.isText
                  ? {
                      name: `read-channel-slug`,
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'interest-detail',
                      },
                    }
                  : {
                      name: 'video-channel-slug',
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'interest-detail',
                      },
                    }
              "
              class="is-desktop-flex"
            />
            <CardFive
              v-show="!artikel_terkini.loading"
              v-for="item in artikel_terkini.items.slice(1, 6)"
              :key="item.id"
              :image="
                item.thumbnail && item.thumbnail.large
                  ? item.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :imageFromUrl="true"
              :duration="item.timeVideo ? item.timeVideo : '06:00'"
              :channel="item.channel.title"
              :channelSlug="item.channel.slug"
              :programSlug="
                item.channel.program ? item.channel.program.slug : ''
              "
              :category="item.category ? item.category.title : ''"
              :categorySlug="item.category ? item.category.slug : ''"
              :title="item.title"
              :isText="item.isText"
              :isAdvertorial="item.isAdvertorial"
              :withProgram="false"
              :to="
                item.isText
                  ? {
                      name: `read-channel-slug`,
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'interest-detail',
                      },
                    }
                  : {
                      name: 'video-channel-slug',
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'interest-detail',
                      },
                    }
              "
              class="is-mobile-flex"
            />
          </div>

          <div
            class="button-show-more is-desktop"
            :class="{ 'mt-5': artikel_terkini.loadingShowMore }"
          >
            <div
              class="btn"
              v-if="
                page < artikel_terkini.pages && !artikel_terkini.loadingShowMore
              "
              @click="showMore"
            >
              LIHAT LEBIH BANYAK
            </div>
            <div
              v-if="artikel_terkini.loadingShowMore"
              class="dot-spin m-auto"
            ></div>
          </div>
        </div>
        <CategoryMiddleAds />
        <div class="secondary">
          <div
            class="video-terpopuler terpopuler"
            v-show="video_terpopuler.items && video_terpopuler.items.length > 0"
          >
            <TitleSection title="VIDEO TERPOPULER" class="title-section" />
            <Shimmer
              width="100%"
              class="border-radius-5 my-3 mb-6"
              height="184px"
              v-if="video_terpopuler.loading"
            />
            <div
              class="populer-header"
              v-if="
                video_terpopuler.items.length > 0 && !video_terpopuler.loading
              "
            >
              <Link
                :to="`/video/${video_terpopuler.items[0].channel.slug}/${video_terpopuler.items[0].slug}?ref=terpopuler`"
              >
                <div class="poster">
                  <ImageResponsive
                    class="img-populars"
                    :imageUrl="video_terpopuler.items[0].thumbnail.medium"
                    :fromUrl="true"
                  />
                  <span class="duration">{{
                    video_terpopuler.items[0].timeVideo
                  }}</span>
                </div>
              </Link>
              <h3 class="title">{{ video_terpopuler.items[0].title }}</h3>
            </div>
            <div class="populer-section">
              <Shimmer
                v-show="video_terpopuler.loading"
                v-for="shimmer in 4"
                :key="`shimmer-${shimmer}`"
                width="100%"
                height="40px"
                class="border-radius-5 mb-4"
              />
              <div
                v-show="!video_terpopuler.loading"
                v-for="(article, index) in video_terpopuler.items.filter(
                  (item, index) => index != 0
                )"
                :key="`populars-video-${index}`"
                class="populer-item-list"
              >
                <div class="item-small">
                  <Link
                    :to="`/video/${article.channel.slug}/${article.slug}?ref=terpopuler`"
                  >
                    <span class="title"
                      ><i class="material-icons">play_circle</i>
                      {{ article.title }}</span
                    >
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="artikel-terpopuler terpopuler"
            v-show="
              artikel_terpopuler.items && artikel_terpopuler.items.length > 0
            "
          >
            <TitleSection
              title="ARTIKEL TERPOPULER"
              class="title-section"
            /><Shimmer
              v-if="artikel_terpopuler.loading"
              width="100%"
              height="184px"
              class="border-radius-5 my-3 mb-6"
            />
            <div
              class="populer-header"
              v-if="
                artikel_terpopuler.items.length > 0 &&
                !artikel_terpopuler.loading
              "
            >
              <Link
                :to="`/video/${artikel_terpopuler.items[0].channel.slug}/${artikel_terpopuler.items[0].slug}?ref=terpopuler`"
              >
                <div class="poster">
                  <ImageResponsive
                    class="img-populars"
                    :imageUrl="artikel_terpopuler.items[0].thumbnail.medium"
                    :fromUrl="true"
                  />
                  <!-- <span class="duration">{{
                    artikel_terpopuler.items[0].timeVideo
                  }}</span> -->
                </div>
              </Link>
              <h3 class="title">{{ artikel_terpopuler.items[0].title }}</h3>
            </div>
            <div class="populer-section">
              <Shimmer
                v-show="artikel_terpopuler.loading"
                v-for="shimmer in 4"
                :key="`shimmer-${shimmer}`"
                width="100%"
                height="40px"
                class="border-radius-5 mb-4"
              />
              <div
                v-show="!artikel_terpopuler.loading"
                v-for="(article, index) in artikel_terpopuler.items.filter(
                  (item, index) => index != 0
                )"
                :key="`populars-video-${index}`"
                class="populer-item-list"
              >
                <div class="item-small">
                  <Link
                    :to="`/video/${article.channel.slug}/${article.slug}?ref=terpopuler`"
                  >
                    <span class="title"> {{ article.title }}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <CategoryRightSquareAds />
        </div>
        <div class="tertiary is-mobile">
          <div class="list-article">
            <template v-if="artikel_terkini.loading">
              <ShimmerCardHomeLandscape
                v-for="(item, index) in 5"
                :key="index"
              />
            </template>
            <CardFive
              v-show="!artikel_terkini.loading"
              v-for="item in artikel_terkini.items.slice(6)"
              :key="item.id"
              :image="
                item.thumbnail && item.thumbnail.large
                  ? item.thumbnail.large
                  : 'https://narasi.tv/storage/images/dummy.png'
              "
              :imageFromUrl="true"
              :duration="item.timeVideo ? item.timeVideo : '06:00'"
              :channel="item.channel.title"
              :channelSlug="item.channel.slug"
              :programSlug="
                item.channel.program ? item.channel.program.slug : ''
              "
              :category="item.category ? item.category.title : ''"
              :categorySlug="item.category ? item.category.slug : ''"
              :title="item.title"
              :isText="item.isText"
              :isAdvertorial="item.isAdvertorial"
              :withProgram="false"
              :to="
                item.isText
                  ? {
                      name: `read-channel-slug`,
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'interest-detail',
                      },
                    }
                  : {
                      name: 'video-channel-slug',
                      params: {
                        channel: item.channel.slug,
                        slug: item.slug,
                      },
                      query: {
                        ref: 'interest-detail',
                      },
                    }
              "
            />
          </div>
          <div
            class="button-show-more is-mobile"
            :class="{ 'mt-5': artikel_terkini.loadingShowMore }"
          >
            <div
              class="btn"
              v-if="
                page < artikel_terkini.pages && !artikel_terkini.loadingShowMore
              "
              @click="showMore"
            >
              LIHAT LEBIH BANYAK
            </div>
            <div
              v-if="artikel_terkini.loadingShowMore"
              class="dot-spin m-auto"
            ></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import config from "../../../config";
import Paginate from "/node_modules/vuejs-paginate/src/components/Paginate";
import CardFive from "../../../components/cards/CardFive.vue";
import CategoryTopAds from "/components/ads/category/CategoryTopAds.vue";
import CategoryMiddleAds from "/components/ads/category/CategoryMiddleAds.vue";
import CategoryRightSquareAds from "/components/ads/category/CategoryRightSquareAds.vue";
import Link from "@/components/Link";
import TitleSection from "@/components/TitleSection";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";
import ShimmerCategory from "@/components/shimmer/Category";
import ShimmerCardHomeLandscape from "@/components/shimmer/CardHomeLandscape";
import Shimmer from "@/components/Shimmer";

Vue.component("paginate", Paginate);

export default {
  watchQuery: true,
  async asyncData({ app, params, query, store, route, redirect, error }) {
    try {
      let response = null;

      if (params.slug !== "semua") {
        response = await app.$axios.$get(
          `${config.CORE_SERVICE_API}interests/v2/${params.slug}`
        );
        store.commit("seo/SET_SEO", {
          title: response.data.meta_title,
          desc: response.data.meta_description,
          image: "https://narasi.tv/new-icon.png",
          url: `${config.BASE_URL}${route.fullPath}`,
          path: `${config.BASE_URL}${route.path}`,
        });
      } else {
        store.commit("seo/SET_SEO", {
          title: `Semua konten seputar ${params.category.split("-").join(" ")}`,
          desc: `Kumpulan konten seputar ${params.category
            .split("-")
            .join(" ")}`,
          image: "https://narasi.tv/new-icon.png",
          url: `${config.BASE_URL}${route.fullPath}`,
          path: `${config.BASE_URL}${route.path}`,
        });
      }

      if (params.category != response.data.parent.slug) {
        return error({ statusCode: 404, message: "Page not found" });
      }

      return { interestDetail: response.data };
    } catch (err) {
      // console.log(err);
    }
  },
  components: {
    Shimmer,
    ShimmerCardHomeLandscape,
    ImageResponsive,
    Icons,
    ShimmerCategory,
    TitleSection,
    Link,
    CardFive,
    CategoryTopAds,
    CategoryMiddleAds,
    CategoryRightSquareAds,
  },
  data() {
    return {
      interestDetail: null,
      page: 1,
      isScroll: false,
      showModal: false,
      swiperOptionsInterests: {
        // freeMode: true,
        pagination: false,
        autoHeight: true,
        calculateHeight: true,
        mousewheel: {
          releaseOnEdges: true,
        },
        breakpoints: {
          1023: {
            slidesPerView: "auto",
            spaceBetween: 10,
            enabled: true,
          },
          320: {
            slidesPerView: "auto",
            spaceBetween: 10,
          },
        },
      },
    };
  },
  head() {
    return this.headReturn;
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      interests: (state) => {
        return state.navbar.interests;
      },
      pinned_article: (state) => {
        return state.navbar.artikel_terkini.items[0];
      },
      artikel_terkini: (state) => {
        return state.navbar.artikel_terkini;
      },
      artikel_terpopuler: (state) => {
        return state.navbar.artikel_terpopuler;
      },
      video_terpopuler: (state) => {
        return state.navbar.video_terpopuler;
      },
    }),
  },
  watch: {},
  methods: {
    async initData() {
      await this.$store.dispatch(
        "navbar/getInterests",
        this.$route.params.category
      );
      await this.$store.dispatch(
        "navbar/getPinnedArticle",
        this.$route.params.category
      );
      await this.$store.dispatch("navbar/setArtikelTerkini", {
        slug: this.$route.params.category,
        interestSlug: this.$route.params.slug,
        page: this.page,
        firstLoad: true,
      });
      await this.$store.dispatch("navbar/getArtikelTerpopuler");
      await this.$store.dispatch("navbar/getVideoTerpopuler");
    },
    // handleScroll() {
    //   const scrollableHeight =
    //     document.documentElement.scrollHeight - window.innerHeight;

    //   if (window.scrollY >= scrollableHeight) {
    //     this.page = this.page + 1;
    //     this.$store.dispatch("navbar/setArtikelTerkini", {
    //       slug: this.$route.params.category,
    //       interestSlug: this.$route.params.slug,
    //       page: this.page,
    //     });
    //     console.log(this.page);
    //     console.log("User has scrolled to the bottom of the page!");
    //   }
    // },
    showMore() {
      this.page = this.page + 1;
      this.$store.dispatch("navbar/setArtikelTerkini", {
        slug: this.$route.params.category,
        interestSlug: this.$route.params.slug,
        page: this.page,
      });
    },
    show() {
      this.showModal = !this.showModal;
    },
  },
  // beforeMount() {
  //   if (process.client) {
  //     window.addEventListener("scroll", this.handleScroll);
  //   }
  // },
  // beforeDestroy() {
  //   if (process.client) {
  //     window.removeEventListener("scroll", this.handleScroll);
  //   }
  // },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 24px 0 48px;
  .modal-interests {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 48px;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    overflow: scroll;
    .item {
      display: block;
      background: #c2c1c6;
      padding: 10px 12px;
      font-size: 18px;
      color: #404040;
      &.active {
        background: #4a25aa;
        color: #ffffff;
      }
    }
  }
  #interests {
    margin-bottom: 24px;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 24px;
    }
    .container {
      @media only screen and (max-width: 1024px) {
        display: flex;
        justify-content: space-between;
      }
      .title-section {
        margin-bottom: 24px;
        text-transform: uppercase;
        @media only screen and (max-width: 1024px) {
          width: 50%;
          margin: 0;
        }
      }
      .dropdown,
      .is-mobile {
        width: 50%;
        .select {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          border: 1px solid #c2c1ca;
          border-radius: 4px;
          padding: 4px 10px;
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
          .label {
            margin: 0;
            font-size: 14px;
            color: #616161;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }

    .swiper-container {
      @media only screen and (max-width: 1024px) {
        padding: 20px;
        margin: -20px;
      }

      .swiper-wrapper {
        height: fit-content !important;
        @media only screen and (max-width: 1024px) {
          flex-wrap: nowrap;
          // flex-wrap: wrap;
        }

        .swiper-slide {
          &.category {
            color: #616161;
            background: white;
            width: fit-content !important;
            border-radius: 50px;
            white-space: nowrap;
            font-size: 16px;
            padding: 10px 30px;
            border: 1px solid #e0e0e0;
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
              padding: 5px 20px;
              margin: 0 5px 5px 0;
            }

            &:hover {
              background: #6242b4;
              color: #fafafa !important;
            }

            &.Link-exact-active {
              background: #4a25aa;
              color: #ffe900 !important;
            }
          }
        }
      }
    }
  }
  #hero {
    margin-bottom: 24px;
    .container {
      position: relative;
      padding: 24px;
      background-repeat: round;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
      }
      .img-thumb {
        flex-basis: 55%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
      }
      .copy-wrapper {
        background: #eeeeee;
        padding: 24px 32px;
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 45%;
        right: 4%;
        @media only screen and (max-width: 1024px) {
          position: relative;
          top: unset;
          right: unset;
          width: 100%;
          transform: unset;
          padding: 16px 16px 24px 16px;
          border-bottom: 6px solid #dddddd;
        }
        .channel {
          font-size: 16px;
          color: #4a25aa;
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            margin-bottom: 8px;
          }
        }
        .title {
          font-size: 24px;
          font-weight: 500;
          color: #0c0b0d;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  #articles {
    .container {
      display: flex;
      flex-wrap: wrap;
      gap: 0;
      justify-content: space-between;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0;
        gap: 24px;
      }
      &.insert-ads {
        width: 100%;
        order: 1;
      }
      .primary {
        order: 2;
        padding-right: 48px;
        @media only screen and (max-width: 1024px) {
          order: 1;
        }
      }
      .primary,
      .tertiary {
        width: 70%;
        @media only screen and (max-width: 1024px) {
          padding: 0 0.75rem;
          width: 100%;
        }
        .title-section {
          margin-bottom: 24px;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 8px;
          }
        }
        ::v-deep .card-five {
          &:first-child {
            padding-top: 0;
          }
          .card-five-body {
            width: 100%;
          }
        }
        .button-show-more {
          text-align: center;
          margin: 32px 0;
          .btn {
            height: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #4a25aa;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 700;
            color: #4a25aa;
            padding: 0 20px;
            @media only screen and (max-width: 1024px) {
              height: 40px;
              &:hover {
                color: #4a25aa !important;
              }
            }
            @media only screen and (min-width: 1024px) {
              &:hover {
                color: #8166c8 !important;
                border-color: #8166c8 !important;
              }
            }
          }
        }
      }
      .tertiary {
        @media only screen and (max-width: 1024px) {
          order: 4;
        }
      }
      .secondary {
        order: 3;
        width: 30%;
        @media only screen and (max-width: 1024px) {
          width: 100%;
        }
        .terpopuler {
          background: #dddddd;
          padding: 16px 24px;
          @media only screen and (max-width: 1024px) {
            padding: 24px 0.75rem;
          }
          &.video-terpopuler {
            margin-bottom: 48px;
            @media only screen and (max-width: 1024px) {
              margin-bottom: 0;
            }
          }
          .title-section {
            margin-bottom: 16px;
          }
          .populer-header {
            margin-bottom: 24px;
            @media only screen and (max-width: 1024px) {
              margin-bottom: 8px;
            }
            ::v-deep a {
              display: block;
              margin-bottom: 16px;
              .poster {
                position: relative;
                .image {
                  width: 100%;
                  height: auto;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
                .duration {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  background: #000000b2;
                  color: #ffffff;
                  font-size: 11px;
                  font-weight: 500;
                  padding: 5px 10px;
                  border-top-left-radius: 5px;
                }
              }
            }
            .title {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #0c0b0d;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
          .populer-section {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(1, 1fr);
            column-gap: 16px;
            @media only screen and (max-width: 1024px) {
              grid-template-columns: repeat(1, 1fr);
              margin-top: unset;
              margin-bottom: 0;
            }

            .populer-item-list {
              border-bottom: 1px solid #a4a4a4;
              display: flex;
              align-items: center;
              &:last-child {
                border-bottom: unset;
              }
              .item-small {
                padding: 16px 0;
                a {
                  display: flex;
                  .title {
                    font-size: 14px;
                    color: #000000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    .material-icons {
                      font-size: 12px;
                      color: #4a25aa;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
