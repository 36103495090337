var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showModal),expression:"showModal"}],staticClass:"modal-interests",on:{"click":function($event){_vm.showModal = false}}},[_c('Link',{staticClass:"item",attrs:{"to":{
        name: 'category',
        params: {
          category: _vm.$route.params.category,
        },
      }}},[_vm._v("\n      Semua\n    ")]),_vm._v(" "),_vm._l((_vm.interests.items),function(item,index){return _c('Link',{key:`btn-interest-mobile-${index}`,staticClass:"item",class:{
        active:
          item.slug == _vm.$route.params.slug ||
          item.slug.includes(_vm.$route.params.slug),
      },attrs:{"to":{
        name: 'category-slug',
        params: {
          category: _vm.$route.params.category,
          slug: item.slug,
        },
      }}},[_vm._v("\n      "+_vm._s(item.title)+"\n    ")])})],2),_vm._v(" "),_c('section',{attrs:{"id":"interests"}},[_c('CategoryTopAds'),_vm._v(" "),_c('div',{staticClass:"container"},[_c('TitleSection',{staticClass:"title-section",attrs:{"title":_vm.$route.params.category == 'tech-edu'
            ? 'TECH & EDU'
            : _vm.$route.params.category.replace(/-/g, ' '),"size":"xl"}}),_vm._v(" "),_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperInsterests",value:(_vm.swiperOptionsInterests),expression:"swiperOptionsInterests",arg:"mySwiperInsterests"}],staticClass:"py-2 is-desktop"},[_c('div',{staticClass:"swiper-wrapper"},[(_vm.interests.loading)?_c('ShimmerCategory'):_vm._e(),_vm._v(" "),_c('Link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.interests.loading),expression:"!interests.loading"}],staticClass:"swiper-slide category",attrs:{"to":{
              name: 'category',
              params: {
                category: _vm.$route.params.category,
              },
            }}},[_vm._v("Semua\n          ")]),_vm._v(" "),_vm._l((_vm.interests.items),function(item,index){return _c('Link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.interests.loading),expression:"!interests.loading"}],key:`btn-interest-${index}`,staticClass:"swiper-slide category",class:_vm.$route.params.slug == item.slug ||
              item.slug.includes(_vm.$route.params.slug)
                ? 'Link-exact-active'
                : '',attrs:{"to":{
              name: 'category-slug',
              params: {
                category: _vm.$route.params.category,
                slug: item.slug,
              },
            }}},[_vm._v(_vm._s(item.title)+"\n          ")])})],2),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})]),_vm._v(" "),_c('div',{staticClass:"dropdown is-mobile",on:{"click":function($event){return _vm.show()}}},[_c('div',{staticClass:"select"},[_c('p',{staticClass:"label"},[_vm._v("\n            "+_vm._s(_vm.$route.params.slug == "semua" && !_vm.interestDetail
                ? "Semua"
                : _vm.interestDetail.title)+"\n          ")]),_vm._v(" "),_c('Icons',{attrs:{"name":"arrow-bottom"}})],1)])],1)],1),_vm._v(" "),_c('section',{attrs:{"id":"hero"}},[(_vm.pinned_article && _vm.pinned_article.channel)?_c('div',{staticClass:"container",style:({
        backgroundImage: `url(${require('@/static/storage/images/bg-hero-banner.png')})`,
      })},[_c('Link',{staticClass:"img-thumb",attrs:{"to":_vm.pinned_article.isText
            ? {
                name: `read-channel-slug`,
                params: {
                  channel: _vm.pinned_article.channel.slug,
                  slug: _vm.pinned_article.slug,
                },
                query: {
                  ref: 'interest-detail',
                },
              }
            : {
                name: 'video-channel-slug',
                params: {
                  channel: _vm.pinned_article.channel.slug,
                  slug: _vm.pinned_article.slug,
                },
                query: {
                  ref: 'interest-detail',
                },
              }}},[_c('ImageResponsive',{attrs:{"imageUrl":_vm.pinned_article.thumbnail && _vm.pinned_article.thumbnail.large
              ? _vm.pinned_article.thumbnail.large
              : 'https://narasi.tv/storage/images/dummy-banner.png',"fromUrl":true,"width":"461","height":"258","widthMobile":"461","heightMobile":"258"}})],1),_vm._v(" "),(_vm.pinned_article && _vm.pinned_article.channel)?_c('div',{staticClass:"copy-wrapper"},[_c('Link',{staticClass:"category",attrs:{"to":{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: _vm.pinned_article.channel.program.slug,
              slugProgram: _vm.pinned_article.channel.slug,
            },
            query: {
              type: 'highlights',
            },
          }}},[_c('p',{staticClass:"channel"},[_vm._v(_vm._s(_vm.pinned_article.channel.title))])]),_vm._v(" "),_c('Link',{attrs:{"to":_vm.pinned_article.isText
              ? {
                  name: `read-channel-slug`,
                  params: {
                    channel: _vm.pinned_article.channel.slug,
                    slug: _vm.pinned_article.slug,
                  },
                  query: {
                    ref: 'interest-detail',
                  },
                }
              : {
                  name: 'video-channel-slug',
                  params: {
                    channel: _vm.pinned_article.channel.slug,
                    slug: _vm.pinned_article.slug,
                  },
                  query: {
                    ref: 'interest-detail',
                  },
                }}},[_c('h1',{staticClass:"title"},[_vm._v("\n            "+_vm._s(_vm.pinned_article.title)+"\n          ")])])],1):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('section',{attrs:{"id":"articles"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"primary"},[_c('TitleSection',{staticClass:"title-section",attrs:{"title":"TERKINI"}}),_vm._v(" "),_c('div',{staticClass:"list-article"},[(_vm.artikel_terkini.loading)?_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{key:index})}):_vm._e(),_vm._v(" "),_vm._l((_vm.artikel_terkini.items.slice(
              1,
              _vm.artikel_terkini.items.length
            )),function(item,index){return _c('CardFive',{directives:[{name:"show",rawName:"v-show",value:(!_vm.artikel_terkini.loading),expression:"!artikel_terkini.loading"}],key:`article-${index}`,staticClass:"is-desktop-flex",attrs:{"image":item.thumbnail && item.thumbnail.large
                ? item.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '06:00',"channel":item.channel.title,"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial,"withProgram":false,"to":item.isText
                ? {
                    name: `read-channel-slug`,
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'interest-detail',
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'interest-detail',
                    },
                  }}})}),_vm._v(" "),_vm._l((_vm.artikel_terkini.items.slice(1, 6)),function(item){return _c('CardFive',{directives:[{name:"show",rawName:"v-show",value:(!_vm.artikel_terkini.loading),expression:"!artikel_terkini.loading"}],key:item.id,staticClass:"is-mobile-flex",attrs:{"image":item.thumbnail && item.thumbnail.large
                ? item.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '06:00',"channel":item.channel.title,"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial,"withProgram":false,"to":item.isText
                ? {
                    name: `read-channel-slug`,
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'interest-detail',
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'interest-detail',
                    },
                  }}})})],2),_vm._v(" "),_c('div',{staticClass:"button-show-more is-desktop",class:{ 'mt-5': _vm.artikel_terkini.loadingShowMore }},[(
              _vm.page < _vm.artikel_terkini.pages && !_vm.artikel_terkini.loadingShowMore
            )?_c('div',{staticClass:"btn",on:{"click":_vm.showMore}},[_vm._v("\n            LIHAT LEBIH BANYAK\n          ")]):_vm._e(),_vm._v(" "),(_vm.artikel_terkini.loadingShowMore)?_c('div',{staticClass:"dot-spin m-auto"}):_vm._e()])],1),_vm._v(" "),_c('CategoryMiddleAds'),_vm._v(" "),_c('div',{staticClass:"secondary"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.video_terpopuler.items && _vm.video_terpopuler.items.length > 0),expression:"video_terpopuler.items && video_terpopuler.items.length > 0"}],staticClass:"video-terpopuler terpopuler"},[_c('TitleSection',{staticClass:"title-section",attrs:{"title":"VIDEO TERPOPULER"}}),_vm._v(" "),(_vm.video_terpopuler.loading)?_c('Shimmer',{staticClass:"border-radius-5 my-3 mb-6",attrs:{"width":"100%","height":"184px"}}):_vm._e(),_vm._v(" "),(
              _vm.video_terpopuler.items.length > 0 && !_vm.video_terpopuler.loading
            )?_c('div',{staticClass:"populer-header"},[_c('Link',{attrs:{"to":`/video/${_vm.video_terpopuler.items[0].channel.slug}/${_vm.video_terpopuler.items[0].slug}?ref=terpopuler`}},[_c('div',{staticClass:"poster"},[_c('ImageResponsive',{staticClass:"img-populars",attrs:{"imageUrl":_vm.video_terpopuler.items[0].thumbnail.medium,"fromUrl":true}}),_vm._v(" "),_c('span',{staticClass:"duration"},[_vm._v(_vm._s(_vm.video_terpopuler.items[0].timeVideo))])],1)]),_vm._v(" "),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.video_terpopuler.items[0].title))])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"populer-section"},[_vm._l((4),function(shimmer){return _c('Shimmer',{directives:[{name:"show",rawName:"v-show",value:(_vm.video_terpopuler.loading),expression:"video_terpopuler.loading"}],key:`shimmer-${shimmer}`,staticClass:"border-radius-5 mb-4",attrs:{"width":"100%","height":"40px"}})}),_vm._v(" "),_vm._l((_vm.video_terpopuler.items.filter(
                (item, index) => index != 0
              )),function(article,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.video_terpopuler.loading),expression:"!video_terpopuler.loading"}],key:`populars-video-${index}`,staticClass:"populer-item-list"},[_c('div',{staticClass:"item-small"},[_c('Link',{attrs:{"to":`/video/${article.channel.slug}/${article.slug}?ref=terpopuler`}},[_c('span',{staticClass:"title"},[_c('i',{staticClass:"material-icons"},[_vm._v("play_circle")]),_vm._v("\n                    "+_vm._s(article.title))])])],1)])})],2)],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.artikel_terpopuler.items && _vm.artikel_terpopuler.items.length > 0
          ),expression:"\n            artikel_terpopuler.items && artikel_terpopuler.items.length > 0\n          "}],staticClass:"artikel-terpopuler terpopuler"},[_c('TitleSection',{staticClass:"title-section",attrs:{"title":"ARTIKEL TERPOPULER"}}),(_vm.artikel_terpopuler.loading)?_c('Shimmer',{staticClass:"border-radius-5 my-3 mb-6",attrs:{"width":"100%","height":"184px"}}):_vm._e(),_vm._v(" "),(
              _vm.artikel_terpopuler.items.length > 0 &&
              !_vm.artikel_terpopuler.loading
            )?_c('div',{staticClass:"populer-header"},[_c('Link',{attrs:{"to":`/video/${_vm.artikel_terpopuler.items[0].channel.slug}/${_vm.artikel_terpopuler.items[0].slug}?ref=terpopuler`}},[_c('div',{staticClass:"poster"},[_c('ImageResponsive',{staticClass:"img-populars",attrs:{"imageUrl":_vm.artikel_terpopuler.items[0].thumbnail.medium,"fromUrl":true}})],1)]),_vm._v(" "),_c('h3',{staticClass:"title"},[_vm._v(_vm._s(_vm.artikel_terpopuler.items[0].title))])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"populer-section"},[_vm._l((4),function(shimmer){return _c('Shimmer',{directives:[{name:"show",rawName:"v-show",value:(_vm.artikel_terpopuler.loading),expression:"artikel_terpopuler.loading"}],key:`shimmer-${shimmer}`,staticClass:"border-radius-5 mb-4",attrs:{"width":"100%","height":"40px"}})}),_vm._v(" "),_vm._l((_vm.artikel_terpopuler.items.filter(
                (item, index) => index != 0
              )),function(article,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.artikel_terpopuler.loading),expression:"!artikel_terpopuler.loading"}],key:`populars-video-${index}`,staticClass:"populer-item-list"},[_c('div',{staticClass:"item-small"},[_c('Link',{attrs:{"to":`/video/${article.channel.slug}/${article.slug}?ref=terpopuler`}},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(article.title))])])],1)])})],2)],1),_vm._v(" "),_c('CategoryRightSquareAds')],1),_vm._v(" "),_c('div',{staticClass:"tertiary is-mobile"},[_c('div',{staticClass:"list-article"},[(_vm.artikel_terkini.loading)?_vm._l((5),function(item,index){return _c('ShimmerCardHomeLandscape',{key:index})}):_vm._e(),_vm._v(" "),_vm._l((_vm.artikel_terkini.items.slice(6)),function(item){return _c('CardFive',{directives:[{name:"show",rawName:"v-show",value:(!_vm.artikel_terkini.loading),expression:"!artikel_terkini.loading"}],key:item.id,attrs:{"image":item.thumbnail && item.thumbnail.large
                ? item.thumbnail.large
                : 'https://narasi.tv/storage/images/dummy.png',"imageFromUrl":true,"duration":item.timeVideo ? item.timeVideo : '06:00',"channel":item.channel.title,"channelSlug":item.channel.slug,"programSlug":item.channel.program ? item.channel.program.slug : '',"category":item.category ? item.category.title : '',"categorySlug":item.category ? item.category.slug : '',"title":item.title,"isText":item.isText,"isAdvertorial":item.isAdvertorial,"withProgram":false,"to":item.isText
                ? {
                    name: `read-channel-slug`,
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'interest-detail',
                    },
                  }
                : {
                    name: 'video-channel-slug',
                    params: {
                      channel: item.channel.slug,
                      slug: item.slug,
                    },
                    query: {
                      ref: 'interest-detail',
                    },
                  }}})})],2),_vm._v(" "),_c('div',{staticClass:"button-show-more is-mobile",class:{ 'mt-5': _vm.artikel_terkini.loadingShowMore }},[(
              _vm.page < _vm.artikel_terkini.pages && !_vm.artikel_terkini.loadingShowMore
            )?_c('div',{staticClass:"btn",on:{"click":_vm.showMore}},[_vm._v("\n            LIHAT LEBIH BANYAK\n          ")]):_vm._e(),_vm._v(" "),(_vm.artikel_terkini.loadingShowMore)?_c('div',{staticClass:"dot-spin m-auto"}):_vm._e()])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }